
import { defineComponent } from "vue";
import CalendarApp1 from "@/components/calendar/CalendarApp1.vue";

export default defineComponent({
  name: "calendar",
  components: {
    CalendarApp1,
  },
});
